<template>
  <div class="arts-item-wrapper">
    <div ref="theArtsItem" class="the-arts-item">
      <div ref="theArtsItemMisBg" class="the-arts-item-mis-bg"></div>
      <div ref="theArtsItemMisFg" class="the-arts-item-mis-fg"></div>
      <div
        ref="theArtsItemBackgroundBlur"
        class="the-arts-item-background-blur">
        <picture
          :style="{
            background:
              'url(' +
              responsiveBackgroundImage.placeholder +
              ') 0% 0% / contain',
          }">
          <source
            :srcSet="responsiveBackgroundImage.placeholder"
            type="image/jpg" />
          <img
            :src="responsiveBackgroundImage.placeholder"
            :width="responsiveBackgroundImage.width"
            :height="responsiveBackgroundImage.height"
            sizes="(min-width: 50px) 50px, 100vw" alt="A blurred poster from a theatre production or event" />
        </picture>
      </div>
      <div ref="theArtsItemBackground" class="the-arts-item-background">
        <picture
          ref="backgroundPicture"
          :style="{
            background:
              'url(' +
              responsiveBackgroundImage.placeholder +
              ') 0% 0% / contain',
          }">
          <source :srcSet="responsiveBackgroundImage.srcSet" type="image/jpg" />
          <img
            :src="responsiveBackgroundImage.src"
            :srcSet="responsiveBackgroundImage.srcSet"
            :width="responsiveBackgroundImage.width"
            :height="responsiveBackgroundImage.height"
            sizes="(min-width: 50px) 50px, 100vw"
            @load="removeBlur"
            alt="A poster from a theatre production or event" />
        </picture>
      </div>
    </div>
    <div ref="itemDescription" class="item-description">
      <div id="production-title-date">
        {{ this.title }}
        <span v-if="String(this.endDate) === ''">
          ({{ this.prettyDate(this.date) }})
        </span>
        <span v-else>
          ({{ this.prettyDate(this.date) }} -
          {{ this.prettyDate(this.endDate) }})
        </span>
      </div>
      <div id="production-venue">
        {{ this.venue }}
      </div>
      <div id="production-jobs">
        <span v-for="(job, index) in sortedJobs" :key="job">
          <span v-if="index == sortedJobs.length - 1">
            {{ job }}
          </span>
          <span v-else>{{ job }}, </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import '../../assets/scripts/blur'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

export default {
  beforeUnmount() {
    if (this.$refs.theArtsItem.vanillaTilt) {
      this.$refs.theArtsItem.vanillaTilt.destroy()
    }
  },
  props: {
    poster: { type: String, required: true },
    backgroundPositionX: { type: Number, default: 0 },
    backgroundPositionY: { type: Number, default: 0 },
    title: {
      type: String,
    },
    venue: {
      type: String,
    },
    jobs: {
      type: Array,
    },
    date: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      glare: false,
      maxEffect: 6,
      scale: 1.5,
      theArtsItemTL: null,
    }
  },
  computed: {
    responsiveBackgroundImage() {
      var responsiveBackgroundImage = null
      try {
        responsiveBackgroundImage = require(`@/assets/images/what-we-do/the-arts/${this.poster}`)
      } catch {
        console.log(
          "Couldn't find image for production poster in The Arts section!"
        )
        responsiveBackgroundImage = require(`@/assets/images/what-we-do/the-arts/missing-poster.png`)
      }
      return responsiveBackgroundImage
    },
    sortedJobs() {
      return this.jobs.slice(0).sort()
    },
  },
  methods: {
    prettyDate(dateString) {
      var options = { year: 'numeric', month: 'short' }
      dateString =
        dateString.substr(6, 4) +
        '-' +
        dateString.substr(3, 2) +
        '-' +
        dateString.substr(0, 2)
      let productionDate = new Date(dateString)
      let formattedDate = productionDate.toLocaleDateString('en-GB', options)
      return formattedDate
    },
    removeBlur() {
      if (this.$refs.backgroundPicture) {
        this.$refs.backgroundPicture.classList.add('is-loaded')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$the-arts-item-width: 30vmin;
$the-arts-item-height: 43vmin;
$the-arts-item-margin-bottom: 22vh;
$the-arts-item-margin-right: 5vmin;

.the-arts-item {
  width: $the-arts-item-width;
  height: $the-arts-item-height;
  white-space: nowrap;
  transform-style: preserve-3d;
  margin-bottom: $the-arts-item-margin-bottom !important;
  margin-right: $the-arts-item-margin-right !important;
  perspective-origin: 0 0;
  transform: perspective(1500px);
  overflow: hidden;
  margin-left: 5vmin;

  @media #{$mq-tiny} {
    width: 69vw;
    height: 50vh;
  }
}

.item-description {
  margin-top: -20vh;
  margin-left: 8vmin;
  margin-right: 5vmin;
  position: absolute;
  color: rgb(219, 219, 219);
  font-family: $main-font;
  font-size: 3.2vmin;
  opacity: 0;
@media #{$mq-tiny} {
    font-size: 0.9em;
}
@media #{$mq-tiny-small} {
    font-size: 0.9em;
}
@media #{$mq-small} {
    font-size: 0.7em;
}
@media #{$mq-medium} {
    font-size: 0.5em;
}
@media #{$mq-large} {
    font-size: 0.44em;
}

@media #{$mq-xlarge} {
    font-size: 0.38em;
}
}

.item-description > ul {
  list-style-type: none;
}

.the-arts-item-mis-bg {
  width: $the-arts-item-width;
  height: $the-arts-item-height;
  background-color: $makeitsopurple;
  position: fixed;

  @media #{$mq-tiny} {
    width: 69vw;
    height: 50vh;
  }
}
.the-arts-item-mis-fg {
  width: $the-arts-item-width;
  height: $the-arts-item-height;
  background-color: $makeitsopurple;
  position: absolute;
  transform-origin: 'top left';
  transform: rotate(45deg) scaleY(2.2) scaleX(2.2);
  opacity: 0;
  z-index: 30;

  @media #{$mq-tiny} {
    width: 69vw;
    height: 50vh;
  }
}

.the-arts-item-background {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  opacity: 0;
  z-index: 3;
  filter: none;
}

// This css adds an alpha to the posters
.the-arts-item-background:after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background-color: rgba(255, 255, 255, 0.07);
  top: 0;
  left: 0;
}

.the-arts-item-background picture > img {
  outline: 1px solid transparent;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.the-arts-item-background picture {
  outline: 1px solid transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(6px);
}

.the-arts-item-background > picture.is-loaded {
  filter: none; /* remove the blur on fullres image */
  transition: filter 1.5s;
}

.the-arts-item-background-blur {
  width: $the-arts-item-width;
  height: $the-arts-item-height;
  position: absolute;
  top: -1px;
  left: -10px;
  transform: perspective(0px);
  opacity: 0;
  transform-origin: 0 0;
  transform: translateZ(-30px);
  filter: blur(30px);

  @media #{$mq-tiny} {
    width: 69vw;
    height: 50vh;
  }
}

.the-arts-item-background-blur picture > img {
  width: $the-arts-item-width;
  height: $the-arts-item-height;
  object-fit: fill;
  margin-left: 2vh;
  margin-top: 2vh;
  filter: blur(20px);
  transform: perspective(0);
  opacity: 0.9;

  @media #{$mq-tiny} {
    width: 69vw;
    height: 50vh;
  }
}

#production-title-date {
  color: $makeitsopurplelight;
  width: 87%;
  margin-bottom: 0.4em;
  margin-top: 1em;
  -webkit-text-stroke: rgba(255, 255, 255, 0.05) 1px;
  text-transform: uppercase;
}

#production-venue {
  // font-size: 2vmin;
  width: 86%;
  margin-bottom: 0.3em;
}

#production-jobs {
  width: 90%;
  // font-size: 1.8vmin;
  color: rgba(255, 255, 255, 0.6);
}
</style>
